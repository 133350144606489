<template>
  <div class="demo_wrap">
    <div class="title">
      考点训练
    </div>
    <nav class="subject_list">
      <div class="subject_item"
           :class="{'active':activeIndex==item.subject_id?true:false}"
           v-for="item,index in subjectList"
           @click="changeSubject(item)"
           :key="index">
        {{item.subject_name}}
      </div>
      <div> </div>
    </nav>
    <div class="point_list box"
         ref="listRef">
      <div class="point_item"
           v-for="item in pointList"
           :key="item.check_point_id">
        <div class='point_desc'>{{item.check_point_desc}}</div>
        <div class="rate">
          <span style="padding-right:4px">星级: </span>
          <div class="stars">
            <img v-for="item in item.check_point_star"
                 :key="item"
                 src="@/assets/icons/icon-星星1.png"
                 alt="">
          </div>
        </div>
        <div class="btns">
          <button class="btn1"
                  @click="showDialog(item.check_point_id)"
                  v-if="item.if_has_check_point_2">二级考点</button>
          <button class="btn2"
                  @click="toInfo(item.check_point_id,1)">开始训练</button>
        </div>
      </div>
    </div>
    <div class="dia_mask"
         v-show="showSub||showTip"></div>
    <div class="dialog_wrap"
         v-show="showSub">
      <div class="dia_title">二级考点
        <span class="close_btn"
              @click="closeDialog">
          <img src="@/assets/icons/关闭.png"
               alt=""></span>
      </div>
      <div class="sub_list">
        <div class="sub_item"
             v-for="item in subPointList"
             :key="item.id">
          <div class="sub_title">{{item.text}}</div>
          <button class="sub_btn"
                  @click="toInfo(item.id,2)">开始训练</button>
        </div>
      </div>

    </div>

    <div class="dialog_wrap"
         v-show="showTip">
      <div class="dia_title">提示
        <span class="close_btn"
              @click="closeDialogTip">
          <img src="@/assets/icons/关闭.png"
               alt=""></span>
      </div>
      <div class="sub_list">
        <div class="contact_warp">
          <div>每科只展示10个考点，若想使用完整功能，请联系：</div>
          <div>
            <div><span>联系电话: </span>4008291883/18902267233</div>
            <div><span>联系电话: </span>4008291883/18902267233</div>
            <div><span>电子邮箱: </span>sunray365@sunray365.com</div>
            <div><span>交流QQ群: </span>529407933</div>
            <div><span>微信客服号: </span> shanlimingshi1/shanlimingshi3</div>
            <!-- <div><span>客服时间:</span>周一至周五：上午9:00-12:00 下午2:00-6:00</div> -->
            <!-- <div><span>注销账号:</span>请联系我们</div> -->
            <div><span>举报电话: </span>020-38329212</div>
            <div><span>举报邮箱: </span>shanlimingshi@126.com</div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { getSubject, getPoint, getSubPoint } from '@/api/demo.js'
export default {
  data () {
    return {
      // subjectList: ['语文', '数学', '语文', '数学', '语文', '数学', '语文', '数学', '语文', '数学'],
      subjectList: [],
      activeIndex: 0,
      showSub: false,
      showTip: false,
      touchStartPos: 0,
      transitionHeight: 0,
      canShowTip: false,
      pointList: [],
      subPointList: []
    }
  },
  mounted () {
    this.geSubjectList()
    this.handlerNodeScroll()
  },
  methods: {

    // 监听元素滚动
    handlerNodeScroll () {
      let that = this
      // 函数防抖
      let fun = that.debounce(e => {
        // 距顶部
        let scrollTop = e.target.scrollTop;
        // 可视区高度
        let clientHeight = e.target.clientHeight;
        // 滚动条总高度
        let scrollHeight = e.target.scrollHeight;
        // 如果当前距离顶部的值加上可视区域的值大于等于总高度，则任务滚动条触底
        if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 20) {   //容差：20px
          this.starTouchEvent()
          this.canShowTip = true
          // this.showTip = true
        } else {
          this.canShowTip = false
        }
      }, 500)

      // 监听滚动
      let box = document.querySelector(".box");
      box.addEventListener("scroll", function (e) {
        fun(e)
      })
    },
    // 函数防抖
    debounce (handle, delay) {
      let timer = null;
      return function () {
        let _self = this,
          _args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          handle.apply(_self, _args)
        }, delay)
      }
    },
    starTouchEvent () {
      let that = this
      let box = document.querySelector(".box");
      box.addEventListener("touchstart", function (e) {
        that.touchStartPos = e.touches[0].pageY;
        box.addEventListener('touchmove', that.touchMoving, false);
      })

      box.addEventListener('touchend', function (e) {
        box.style.paddingBottom = 0;
        that.transitionHeight = 0
        that.touchStartPos = 0
        this.canShowTip = false
        box.removeEventListener('touchmove', that.touchMoving, false);
      }, false);
    },

    touchMoving (e) {
      let that = this
      let box = document.querySelector(".box");
      this.transitionHeight = e.touches[0].pageY - that.touchStartPos; // 记录差值
      if (that.transitionHeight < 0 && this.transitionHeight < -40) {
        box.style.paddingBottom = -that.transitionHeight + 'px';
        if (that.transitionHeight < -160 && this.canShowTip) {
          that.showTip = true
          box.style.paddingBottom = 0;
        }
      }
    },

    async geSubjectList () {
      const { data } = await getSubject()
      this.subjectList = data.list
      if (this.subjectList && this.subjectList.length) {
        if (this.$route.params.subject_id) {
          this.activeIndex = this.$route.params.subject_id
        } else {

          this.activeIndex = this.subjectList[0].subject_id
        }
        this.getPointData()
      }

    },
    changeSubject (item) {
      if (this.activeIndex != item.subject_id) {
        this.activeIndex = item.subject_id
        this.$route.query.subject_id = this.activeIndex
        this.getPointData()
      }
    },
    async getPointData () {
      const { data } = await getPoint({
        subject_id: this.activeIndex,
        district_id: 14 //未登录地区先固定
      })
      this.pointList = data.list.slice(0, 10)
      this.$refs.listRef.scrollTop = 0
    },

    async showDialog (check_point_id) {
      const { data } = await getSubPoint({
        checkpoints: check_point_id
      })
      this.subPointList = data.list
      this.showSub = true
    },
    closeDialogTip () {
      this.showTip = false
    },
    closeDialog () {
      this.showSub = false
    },
    toInfo (id, type) {
      this.$router.push({
        path: '/demoTopic',
        query: {
          id: id,
          subject_id: this.activeIndex,
          type //1考点，2二级考点
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.demo_wrap {
  font-size: 20px;
  button {
    outline: 0;
  }
  .title {
    background: #2196f3;
    text-align: center;
    color: #fff;
    padding: 11px;
    line-height: 22px;
    font-size: 16px;
    // font-size: 24px;
  }
  .subject_list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scrollbar-width: none;
    padding: 0 4px;
    border: 1px solid #e6e6e6;
    .subject_item {
      white-space: nowrap;
      // margin: 0px 15px;
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
    .active {
      position: relative;
      color: #2196f3;
      &:before {
        position: absolute;
        width: calc(100% - 24px);
        height: 2px;
        content: "";
        background: #2196f3;
        bottom: 0;
        left: 12px;
      }
    }
  }
  .point_list {
    padding: 0 4px;
    padding-bottom: 20px;
    height: calc(100vh - 88px);
    overflow: auto;
    .point_item {
      margin: 12px;
      padding: 12px;
      box-shadow: 1px 1px 3px #ccc;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
      background: #fff;
    }
  }
  .point_desc {
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    margin-bottom: 4px;
  }
  .rate {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
  .point_desc,
  .rate {
    text-align: left;
  }
  .stars {
    display: inline-block;
    vertical-align: middle;
    height: 12px;
    img {
      width: 12px;
      height: 12px;
      padding-left: 2px;
    }
  }
  .btns {
    text-align: right;
    margin-top: 13px;
    button {
      border: 0;
      padding: 3px 12px;
      border-radius: 100px 100px 100px 100px;
      line-height: 22px;
      font-size: 12px;
      font-weight: 400;
    }
    .btn1 {
      color: #666666;
      border: 1px solid #999999;
      margin-right: 8px;
      background: #fff;
    }
    .btn2 {
      color: #fff;
      line-height: 22px;
      background: #2196f3;
    }
  }
  .dia_mask,
  .dialog_wrap {
    position: fixed;
  }
  .dia_mask {
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .dialog_wrap {
    top: 18%;
    // left: 50%;
    // margin-left: -156px;
    // width: 312px;
    width: calc(100% - 64px);
    margin: 0 32px;
    box-sizing: border-box;
    height: 60%;
    padding: 24px 0;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px 12px 12px 12px;
    .dia_title {
      position: relative;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 8px;
    }

    .close_btn {
      position: absolute;
      top: 0px;
      right: 24px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .sub_list {
      padding: 0 24px;
      height: calc(100% - 32px);
      overflow: auto;
    }
    .contact_warp {
      margin-top: 5px;
      font-size: 16px;
      > div {
        // margin-bottom: 10px;
        &:nth-child(1) {
          color: #666;
          line-height: 28px;
          margin-bottom: 10px;
        }
        &:nth-child(2) {
          color: #333;
          font-size: 16px;
          line-height: 24px;
          > div {
            margin-bottom: 10px;
          }
        }
      }
    }
    .sub_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px 0;
      .sub_title {
        flex: 1;
        font-size: 15px;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
      .sub_btn {
        width: 72px;
        height: 28px;
        font-size: 12px;
        font-weight: 400;
        color: #2196f3;
        line-height: 22px;
        border-radius: 100px 100px 100px 100px;
        border: 1px solid #2196f3;
        background: #fff;
        margin-left: 16px;
      }
    }
  }
}
.subject_list::-webkit-scrollbar,
.point_list::-webkit-scrollbar {
  display: none !important;
  width: 0px;
  height: 0px;
}
</style>